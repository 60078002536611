<template>
  <sign-page
    title-text="身份证"
    :request="request"
    :column-list="columnList"
    :title-menus="[]"
    table-size="large"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal :mask-closable="false" v-model="modal" v-if="modal" width="88vw" :title="chooseData.name + ' 身份证'" theme="mh-blackt">
      <worker-id-card-img
        :worker-data="chooseData"
        :funs="{
          get: $authFunsProxy.idcard_file,
          update: $authFunsProxy.file_update
        }"
        @idcardFileChange="idcardFileChange">
      </worker-id-card-img>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  workerRequest as request
} from '../../api'

import WorkerIdCardImg from './worker/workerIdCardImg'

export default {
  components: {
    WorkerIdCardImg
  },
  methods: {
    async loadData () {
      let datas = []
      if (this.$authFunsProxy.get) {
        datas = await request.get()
      } else if (this.$authFunsProxy.getMy) {
        datas = await request.getMy()
      } else if (this.$authFunsProxy.getMyOrg) {
        datas = await request.getMyOrg()
      } else if (this.$authFunsProxy.getMyOrgContainChild) {
        datas = await request.getMyOrgContainChild()
      }
      return datas
    },
    tableAction (parm, vm) {
      this.chooseData = parm.data
      this.pageVm = vm
      this.modal = true
    },
    async idcardFileChange (parm) {
      let data = {}
      if (parm.type === 'front') {
        data.idCardFrontFileId = parm.fileData.id
      }
      if (parm.type === 'back') {
        data.idCardBackFileId = parm.fileData.id
      }
      await request.update(this.chooseData.id, data)
      await this.pageVm.loadData()
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'idcard_file',
        label: '扫描件'
      }]
      return data
    },
    columnList: {
      get () {
        let data = [{
          title: '姓名',
          field: 'name',
          sort: true
        },
        {
          title: '工号',
          field: 'code',
          sort: true
        },
        {
          title: '身份证号',
          field: 'idNo',
          sort: true
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      modal: false,
      chooseData: null,
      pageVm: null
    }
  }
}
</script>

<style lang="less" scoped>
.img-c {
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    font-size: 1.4rem;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
</style>